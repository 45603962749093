import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { KREDILINE_SERVER_URL } from "src/app/app.constants";
import {
  GET_DOCUMENT_TAG_DATA,
  TAG_DOCUMENTS,
} from "src/app/shared/constants/Api.constants";
import { getProperty } from "src/app/utils/app.utils";
import { SubscriptionReviewSupportService } from "./customer-group/subscription-review/subscription-review-support.service";
import { FileService } from "./files/file.service";
import { UiConfigService } from "./ui-config.service";
import { UI_COMPONENTS } from "src/app/constants/ui-config";
import { get } from "lodash";
import { AssociateLenderService } from "./associate-lender/associate-lender.service";
import { KcreditLoanService } from "../loan/kcredit-loan.service";
import { PrincipalService } from "src/app/core";

interface UniqueProofTypes {
  applicants: string[];
  coApplicants: string[];
}

@Injectable({
  providedIn: "root",
})
export class DocumentsService implements OnInit {
  private selectedDocuments = new BehaviorSubject<{ [category: string]: string[] }>({});
  selectedCategory: string | null = null;

  documentSizeLimit: number = null;
  isDcbLender: boolean = false;
  assignee: string = undefined;
  currentUser: string = undefined;

  constructor(
    private http: HttpClient,
    private subscriptionReviewService: SubscriptionReviewSupportService,
    private fileService: FileService,
    private uiConfigService: UiConfigService,
    private associateLenderService: AssociateLenderService,
    private loanService: KcreditLoanService,
    private principalService: PrincipalService
  ) {}

  ngOnInit() {
    this.isDcbLender = (
      this.associateLenderService.getLenderCode() || ""
    ).includes("DCB");
    let loanDetails = this.loanService.getLoanDetails();
    this.currentUser = this.principalService.getUserLogin();
    this.assignee = getProperty(
      loanDetails,
      "loanApplicationDTO.assignee"
    );
  }
  loadDocumentProperties() {
    this.uiConfigService
      .getUiConfigBySection(
        UI_COMPONENTS.DOCUMENT_TAGS,
        UI_COMPONENTS.LOAN_REVIEW
      )
      .subscribe((response: Array<any>) => {
        let documentConfigs = this.uiConfigService.getUiConfigurationsBySection(
          response,
          UI_COMPONENTS.DOCUMENT_TAGS,
          true
        );
        this.documentSizeLimit = getProperty(
          documentConfigs,
          "documentSizeLimit",
          null
        );
      });
  }

  tagDocuments(request: any = null): Promise<any> {
    return this.http
      .post(`${KREDILINE_SERVER_URL}${TAG_DOCUMENTS}`, request)
      .toPromise();
  }

  getLoanDocumentTags(partnerId = null, branchId = null): Promise<any> {
    let params = new HttpParams();
    params = params.append("partnerId", partnerId).append("branchId", branchId);
    return this.http
      .get(`${KREDILINE_SERVER_URL}${GET_DOCUMENT_TAG_DATA}`, { params })
      .toPromise();
  }

  extractDocsTypes(docs, targetArray: string[]) {
    if (!docs) return;
    docs.forEach((doc) => {
      if (doc.mandatory) {
        targetArray.push(...doc.mandatory);
      }
      if (doc.optionalDocs) {
        targetArray.push(...doc.optionalDocs);
      }
    });
  }
  extractUniqueProofTypes(data): UniqueProofTypes {
    const uniqueProofTypes: UniqueProofTypes = {
      applicants: [],
      coApplicants: [],
    };

    data.loanTypes.forEach((loanType) => {
      loanType.stages.forEach((stage) => {
        this.extractDocsTypes(
          stage.applicantRequiredDocs,
          uniqueProofTypes.applicants
        );
        this.extractDocsTypes(
          stage.coapplicantRequiredDocs,
          uniqueProofTypes.coApplicants
        );
      });
    });

    uniqueProofTypes.applicants = Array.from(
      new Set(uniqueProofTypes.applicants)
    );
    uniqueProofTypes.coApplicants = Array.from(
      new Set(uniqueProofTypes.coApplicants)
    );

    return uniqueProofTypes;
  }

  convertDocsToFiles(documents: any[], updatedDocument: any): File[] {
    const files: File[] = [];
    documents.forEach((doc) => {
      let docId: number = getProperty(doc, "id", "");
      if (!docId) {
        return;
      }
      let docImage: string = updatedDocument[docId];
      if (!docImage) {
        return;
      }
      let imageBlob = this.subscriptionReviewService.dataURItoBlob(docImage);
      if (imageBlob) {
        const imageFile = new File([imageBlob], docId + ".jpg", {
          type: "image/jpeg",
        });
        files.push(imageFile);
      }
    });
    return files;
  }
  async uploadDocumentsToS3(
    files: File[],
    updatedDocument: any = {},
    loanReviewDocs: Array<any> = [],
    partnerId: number = null
  ) {
    if (files.length > 0 && partnerId) {
      let fileDTOs = await this.fileService
        .uploadKaleidofinImage(files, "S3", partnerId)
        .toPromise();
      if (fileDTOs !== undefined) {
        const updatedDocTypes = Object.keys(updatedDocument);
        let currentFile = 0;

        loanReviewDocs.forEach((doc) => {
          if (updatedDocTypes.indexOf(doc.id + "") !== -1) {
            doc.documentFileId = fileDTOs[currentFile].id;
            currentFile = currentFile + 1;
          }
        });
      }
    }
    return loanReviewDocs;
  }

  async saveEditedDocs(
    documents: Array<any> = [],
    updatedDocument: any = {},
    partnerId: number = null
  ) {
    const files: Array<any> = this.convertDocsToFiles(
      documents,
      updatedDocument
    );

    return await this.uploadDocumentsToS3(
      files,
      updatedDocument,
      documents,
      partnerId
    );
  }
  checkAndDisableActions(doc: any = {}) {
    if (this.isDcbLender) {
      if (!this.assignee) {
        return true;
      }
      if (this.currentUser !== this.assignee) {
        return true;
      }
    }

    if (doc) {
      return (
        ["REJECT", "ACCEPT", "Rejected"].includes(
          get(doc, "reviewStatus", "")
        ) ||
        ["REJECT", "ACCEPT", "Rejected"].includes(
          get(doc, "verificationStatus", "")
        ) ||
        !(get(doc, "documentFileId", null) || get(doc, "fileId", null))
      );
    }
    return false;
  }


  getSelectedDocuments(): Observable<{ [category: string]: string[] }> {
    return this.selectedDocuments.asObservable();
  }

  updateSelectedDocuments(category: string, documents: string[]): void {
    const currentSelection = this.selectedDocuments.getValue();
    currentSelection[category] = documents;
    this.selectedDocuments.next(currentSelection);
  }

  clearSelection(category: string): void {
    const currentSelection = this.selectedDocuments.getValue();
    currentSelection[category] = [];
    this.selectedDocuments.next(currentSelection);
  }

  clearAll(): void {
    this.selectedDocuments.next({});
  }
}
