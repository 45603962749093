import { Component, Input, OnInit } from "@angular/core";
import { UiConfigService } from "../../services/ui-config.service";
import { SECTION_INFORMATION } from "src/app/constants/ui-config";
import { getProperty } from "src/app/utils/app.utils";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LoanReviewService } from "../../report/loan-review.service";
import { DependableFieldValidationService } from "../../dependable-field-validation.service";
import { AuthorizationService } from "../../services/authorization.service";

@Component({
  selector: "app-customer-demand-schedule",
  templateUrl: "./customer-demand-schedule.component.html",
  styleUrls: ["./customer-demand-schedule.component.scss"],
})
export class CustomerDemandScheduleComponent implements OnInit {
  @Input() loanId: number = null;
  @Input() editSections: boolean = false;

  panelOpenState: boolean = true;
  editDetails: boolean = false;
  demandSchedule: any = {};
  paymentSchedule: Array<any> = [];
  uiFieldsMap: Array<any> = [];
  initialSchedule: any = {};
  hasAuthority: boolean = false;
  constructor(
    private uiConfigService: UiConfigService,
    private snackBar: MatSnackBar,
    private loanReviewService: LoanReviewService,
    private dependableFieldCheck: DependableFieldValidationService,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit(): void {
    this.hasAuthority = this.authorizationService.hasAuthority(
      SECTION_INFORMATION.DEMAND_SCHEDULE.authority
    );
    this.uiConfigService
      .getUiInformationBySections(
        SECTION_INFORMATION.DEMAND_SCHEDULE.sectionKey,
        this.loanId
      )
      .subscribe(
        (response: any) => {
          const subSection: Array<any> = getProperty(
            response,
            "subSections",
            []
          );
          this.demandSchedule = getProperty(subSection[0], "fields", {});
          this.initialSchedule = JSON.parse(
            JSON.stringify(this.demandSchedule)
          );
          const paymentScheduleField = getProperty(subSection[1], "fields", {});
          this.paymentSchedule = getProperty(
            paymentScheduleField,
            "accountDetailCalculationModelList.value",
            []
          );
        },
        (error) => console.error(error)
      );

    this.uiConfigService
      .getUiConfigBySection(SECTION_INFORMATION.DEMAND_SCHEDULE.sectionKey)
      .subscribe((response: any = {}) => {
        const uiConfig = this.uiConfigService.getUiConfigurationsBySection(
          response,
          SECTION_INFORMATION.DEMAND_SCHEDULE.sectionKey,
          true
        );
        this.uiFieldsMap = getProperty(uiConfig, "uiFieldsMap", []);
      });
  }

  toggleEditDetails(event: Event) {
    event.stopPropagation();
    this.editDetails = !this.editDetails;
  }

  cancel(event: Event) {
    event.stopPropagation();
    this.editDetails = !this.editDetails;
    this.demandSchedule = JSON.parse(JSON.stringify(this.initialSchedule));
  }

  getPayload(): Object {
    const payload: Object = {};
    Object.keys(this.demandSchedule).forEach((key) => {
      const value = getProperty(this.demandSchedule[key], "value", null);
      payload[key] = value;
    });
    return payload;
  }

  save(event: Event) {
    event.stopPropagation();
    this.editDetails = !this.editDetails;
    const payload = this.getPayload();
    this.uiConfigService
      .updateUiFields(
        SECTION_INFORMATION.DEMAND_SCHEDULE.apiKey,
        payload,
        this.loanId
      )
      .subscribe(
        (response: any) => {
          const applicationStatus: string =
            this.loanReviewService.getLoanStatus();
          this.dependableFieldCheck.getLoanStageCheck(
            response,
            this.loanId,
            applicationStatus
          );
          this.snackBar.open(`Updated successfully`, "", {
            duration: 3000,
          });
          location.reload();
        },
        (error) => {
          console.error(error);
          this.demandSchedule = JSON.parse(
            JSON.stringify(this.initialSchedule)
          );
          this.snackBar.open(`Error updating Demand Schedule`, "", {
            duration: 3000,
          });
        }
      );
  }
}
