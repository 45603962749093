<button
  [hidden]="isEditing"
  mat-raised-button
  *ngIf="panelOpenState"
  type="button"
  class="btn btn-primary"
  data-dismiss="modal"
  [disabled]="!disableEdit || isRecalculationInProgress"
  (click)="onEdit($event)"
>
  <span class="fa fa-pencil"></span>&nbsp;<span
    jhiTranslate="entity.action.edit"
    >Edit</span
  >
</button>
<div class="confirm-buttons" [hidden]="!isEditing">
  <button
    mat-raised-button
    type="button"
    class="btn-secondary"
    data-dismiss="modal"
    (click)="onCancel($event)"
  >
    <span jhiTranslate="entity.action.cancel">Cancel</span>
  </button>
  <button
    mat-raised-button
    type="button"
    class="btn-primary"
    data-dismiss="modal"
    (click)="onSave($event)"
  >
    <span jhiTranslate="entity.action.save">Save</span>
  </button>
</div>
