import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { KycVerificationService } from "../../services/kyc-verification.service";

const PAYLOAD_MAP = {
  Name: "name",
  "Father's Name": "fatherName",
  Gender: "gender",
  DOB: "dateOfBirth",
  "Current and Permanent Address": "address",
  Address: "address",
  City: "city",
  District: "district",
  Pincode: "pincode",
  State: "state",
  "Date of Birth": "dateOfBirth",
};

@Component({
  selector: "app-kyc-results-update-popup",
  templateUrl: "./kyc-results-update-popup.component.html",
  styleUrls: ["./kyc-results-update-popup.component.scss"],
})
export class KycResultsUpdatePopupComponent {
  updateStatus: "idle" | "success" | "failed" = "idle";
  constructor(
    public dialogRef: MatDialogRef<KycResultsUpdatePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private kycVerificationService: KycVerificationService
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }

  updateData(): void {
    if (this.updateStatus === "success") {
      return;
    }
    let payload = {};
    this.data?.results?.forEach((value) => {
      payload[PAYLOAD_MAP[value?.label]] = value?.updatedValue;
    });
    payload["id"] = this.data?.entityId;
    payload["customerType"] = this.data?.customerType;
    payload["documentIdNo"] = this.data?.documentIdNo;
    payload["documentType"] = this.data?.documentType;
    payload["purpose"] = this.data?.purpose;

    this.kycVerificationService.updateCustomerKyc(payload).subscribe(
      () => {
        this.updateStatus = "success";
      },
      (error) => {
        console.error(error);
        this.updateStatus = "failed";
      }
    );
  }
}
