import { DomSanitizer } from "@angular/platform-browser";
import { Component, Input, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { MatSnackBar } from "@angular/material/snack-bar";
import { get } from "lodash";
import { SECTION_INFORMATION } from "src/app/constants/ui-config";
import { getProperty } from "src/app/utils/app.utils";
import { DependableFieldValidationService } from "../../dependable-field-validation.service";
import { KcreditLoanDetailsModel } from "../../loan/kcredit-loanDetails.model";
import { LoanReviewService } from "../../report/loan-review.service";
import { BusinessRuleEngineService } from "../../services/business-rule-engine/business-rule-engine.service";
import { UiConfigService } from "../../services/ui-config.service";
import { MatDialog } from "@angular/material/dialog";
import { RecalculateBrePopupComponent } from "../recalculate-bre-popup/recalculate-bre-popup.component";
import { RecalculateBreService } from "../../services/recalculate-bre/recalculate-bre.service";
import { BreStatusResponse } from "../../services/recalculate-bre/bre.constants";
@Component({
  selector: "app-bre",
  templateUrl: "./bre.component.html",
  styleUrls: ["./bre.component.scss"],
})
export class BreComponent implements OnInit {
  @Input() loanDetails: KcreditLoanDetailsModel;
  /** To be Reworked */
  @Input() partnerApplicationId: any = undefined;
  @Input() loanApplicationId: any = undefined;
  @Input() partnerCustomerId: any = undefined;
  @Input() breNeeded: boolean = false;
  @Input() enableRecalculateBre: boolean = true;
  @Input() fromEntry: boolean = false;
  @Input() editSections: boolean = false;

  loanId: number = null;

  enableEdit: boolean = false;

  breData: any = {};
  breAction: string = "";
  breStatus: any = "failed";
  enableViewReport: boolean = false;
  /** To be Reworked */

  openBreSection: boolean = true;

  eligibilityCalculations: any = {};
  eligibilityNorms: any = {};
  constructor(
    private matIconRegistry: MatIconRegistry,
    private uiConfigService: UiConfigService,
    private breService: BusinessRuleEngineService,
    private snackBar: MatSnackBar,
    private loanReviewService: LoanReviewService,
    private dependableFieldCheck: DependableFieldValidationService,
    private domSanitizer: DomSanitizer,
    private dialog: MatDialog,
    private recalculateBREService: RecalculateBreService
  ) {
    this.matIconRegistry.addSvgIcon(
      "pdf-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/common/bi_file-earmark-pdf.svg"
      )
    );
  }

  ngOnInit() {
    this.breService
      .getBreResponse()
      .subscribe((response: BreStatusResponse) => {
        this.setBreDetails(response);
      });
    this.loanId = getProperty(this.loanDetails, "loanApplicationDTO.id", null);
    this.uiConfigService
      .getUiInformationBySections("BRE_DETAILS", this.loanId)
      .subscribe(
        (response: any) => {
          this.eligibilityNorms = getProperty(
            response,
            "subSections[0].fields",
            {}
          );

          this.eligibilityCalculations = getProperty(
            response,
            "subSections[1].fields",
            {}
          );
        },
        (error) => console.error(error)
      );

    if (!this.breNeeded) return;

    this.breService
      .fetchBreCondition(this.partnerCustomerId, this.partnerApplicationId)
      .subscribe(
        (response: BreStatusResponse) => {
          this.breService.setBreResponse(response);
        },
        (error) => {
          this.handleError(error);
        }
      );
  }

  setBreDetails(breResponse: BreStatusResponse): void {
    this.breData = breResponse;
    this.breAction = getProperty(this.breData, "decision", "FAILED") || "";
    this.breStatus = this.breAction.toLowerCase().includes("pass")
      ? "accept"
      : "failed";
    this.enableViewReport = !["PENDING", "FAILED"].includes(this.breAction);
  }

  handleError(error: any): void {
    console.error(error);
    this.breData = { decision: "FAILED" };
    this.breAction = getProperty(error, "error.message", "FAILED");
    this.enableViewReport = false;
  }

  openBreReport(event: Event) {
    event.stopPropagation();
    const applicantName = this.loanReviewService.getApplicantName();
    this.breData = {
      ...this.breData,
      applicantName: applicantName,
      loanId: this.loanApplicationId,
      partnerAppId: this.partnerApplicationId,
      partnerCustomerId: this.partnerCustomerId,
    };
    const serializedData = window.btoa(
      encodeURIComponent(JSON.stringify(this.breData))
    );
    const queryParams = new URLSearchParams();
    queryParams.set("data", serializedData);
    const currentUrl = new URL(window.location.href);
    const targetUrl = new URL(
      `${currentUrl.href}/bre-report?${queryParams.toString()}`
    );
    if (targetUrl.origin === currentUrl.origin) {
      window.open(targetUrl.toString(), "_blank");
    } else {
      console.error("Attempted open redirect detected and prevented.");
    }
  }

  recalculateBre(event) {
    event.stopPropagation();
    this.dialog.open(RecalculateBrePopupComponent, {
      width: "40vw",
      disableClose: true,
      panelClass: "custom-overlay",
    });
    setTimeout(() => {
      this.recalculateBREService.recalculateBRE(this.loanId).subscribe(
        (breResponse: BreStatusResponse) => {
          this.breService.setBreResponse(breResponse);
          this.recalculateBREService.finishRecalculation();
          this.uiConfigService.checkApprovalButton(this.loanId);
        },
        (error) => {
          this.handleError(error);
          this.uiConfigService.checkApprovalButton(this.loanId);
        }
      );
    }, 1500);
  }

  handleBreSection() {
    this.openBreSection = !this.openBreSection;
  }

  getPayload(): Object {
    const payload: any = {};
    Object.keys(this.eligibilityNorms).forEach((key) => {
      const value: string = get(this.eligibilityNorms[key], "value", null);
      payload[key] = value;
    });
    return payload;
  }

  save(): void {
    const payload: any = this.getPayload();
    this.uiConfigService
      .updateUiFields(
        SECTION_INFORMATION.BRE_WITH_ELIGIBILITY_NORMS.apiKey,
        payload,
        this.loanId
      )
      .subscribe(
        (response: any) => {
          const applcationStatus: string =
            this.loanReviewService.getLoanStatus();
          this.dependableFieldCheck.getLoanStageCheck(
            response,
            this.loanId,
            applcationStatus
          );
          this.snackBar.open(`Updated successfully`, "", {
            duration: 3000,
          });
          location.reload();
        },
        (error) => {
          console.error(error);
        }
      );
  }
}
