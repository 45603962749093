<mat-form-field appearance="none" class="search-textfield">
  <mat-icon matPrefix style="padding: 0px 5px" (click)="search()" onKeyPress=""
    >search</mat-icon
  >
  <input
    matInput
    placeholder="Search"
    [(ngModel)]="userName"
    (keyup.enter)="search()"
  />
  <div matSuffix class="suffix-icons">
    <button mat-icon-button *ngIf="userName" (click)="clearUserName()">
      <mat-icon>clear</mat-icon>
    </button>
    <button
      mat-icon-button
      [matMenuTriggerFor]="filterMenu"
      #menuTrigger="matMenuTrigger"
    >
      <mat-icon>filter_list</mat-icon>
    </button>
  </div>
</mat-form-field>

<mat-menu
  #filterMenu="matMenu"
  class="filter-menu"
  [overlapTrigger]="false"
  [xPosition]="'before'"
  [yPosition]="'below'"
>
  <div
    class="filter-menu-container"
    (click)="$event.stopPropagation()"
    onKeyPress=""
  >
    <div class="filter-menu-item">
      <div class="filter-menu-label date-item">Generated date</div>
      <div class="filter-menu-content date-picker-group">
        <mat-form-field appearance="none">
          <mat-label class="label">Start date</mat-label>
          <input
            matInput
            [matDatepicker]="startPicker"
            [(ngModel)]="startDate"
            (dateChange)="onDateChange($event, 'startDate')"
            [max]="maxDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="none">
          <mat-label class="label">End date</mat-label>
          <input
            matInput
            [matDatepicker]="endPicker"
            [(ngModel)]="endDate"
            (dateChange)="onDateChange($event, 'endDate')"
            [max]="maxDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="filter-menu-item">
      <div class="filter-menu-label">Report type</div>
      <div class="filter-menu-content">
        <app-report-type-field></app-report-type-field>
      </div>
    </div>
    <div class="filter-menu-item">
      <div class="filter-menu-label">{{isDCBMFI ? 'BC Name' : 'Partner'}}</div>
      <div class="filter-menu-content">
        <app-partner-field></app-partner-field>
      </div>
    </div>
    <div class="filter-menu-item">
      <div class="filter-menu-label">Loan type</div>
      <div class="filter-menu-content">
        <app-loan-type-field></app-loan-type-field>
      </div>
    </div>
  </div>
  <div class="filter-menu-actions">
    <div *ngIf="dateErrorMessage" class="date-error">
      {{ dateErrorMessage }}
    </div>
    <button mat-button (click)="cancel()">Cancel</button>
    <button
      mat-button
      class="btn btn-primary"
      [disabled]="!!dateErrorMessage"
      (click)="search()"
    >
      Search
    </button>
  </div>
</mat-menu>
