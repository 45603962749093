import { Component, Input, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { UI_COMPONENTS } from "src/app/constants/ui-config";
import { getProperty } from "src/app/utils/app.utils";
import { KycVerificationService } from "../../services/kyc-verification.service";
import { UiConfigService } from "../../services/ui-config.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { isEmpty, set } from "lodash";
import { MatDialog } from "@angular/material/dialog";
import { KycResultsUpdatePopupComponent } from "../../atoms/kyc-results-update-popup/kyc-results-update-popup.component";

@Component({
  selector: "app-kyc-results-table",
  templateUrl: "./kyc-results-table.component.html",
  styleUrls: ["./kyc-results-table.component.scss"],
})
export class KycResultsTableComponent implements OnInit {
  @Input() loanId: number = null;
  @Input() type: string = null;
  @Input() idNo: string = "";
  @Input() entityType: string = "";
  @Input() entityId: number;
  @Input() purpose: string = "";

  matchValueData: BehaviorSubject<string> = new BehaviorSubject<string>("");
  matchValueClass: BehaviorSubject<string> = new BehaviorSubject<string>("");

  showDetail: boolean = true;
  includeGovDB: boolean = false;
  enableVerifyBtn: boolean = false;

  matchValue: string = "";
  resultsOrder: Array<any> = [];
  data: any = {};
  results: Array<any> = [];
  resultsToUpdate: Array<any> = [];  
  headers: Array<string> = [];
  hideKycColumn: boolean = false;
  rows: Array<any> = [];
  headingSubtitle: any = {};
  allowedTypes: string[] = [
    "Gender",
    "Father's Name",
    "DOB",
    "Current and Permanent Address",
    "Address",
    "City",
    "District",
    "Pincode",
    "State",
    "Name",
    "Date of Birth",
  ];
  constructor(
    private kycVerificationService: KycVerificationService,
    private uiConfigService: UiConfigService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    const configResponse = await this.uiConfigService
      .getUiConfig(UI_COMPONENTS.LOAN_REVIEW)
      .toPromise();
    const kycVerificationResultsConfig = configResponse.find(
      (config) =>
        getProperty(config, "sectionName", "") ===
        UI_COMPONENTS.KYC_VERIFICATION_RESULTS
    );
    const uiConfig = JSON.parse(
      getProperty(kycVerificationResultsConfig, "uiConfigurations", "{}")
    );
    const headers = JSON.parse(getProperty(uiConfig, "headers", "{}"));
    this.hideKycColumn = JSON.parse(
      getProperty(uiConfig, "hideKycColumn", false)
    );
    const subtitle = getProperty(uiConfig, "headingSubtitle", {});
    if (!isEmpty(subtitle) && this.type) {
      this.headingSubtitle = subtitle[this.type.toLowerCase()];
    }
    this.resultsOrder = getProperty(uiConfig, "resultsOrder", []);
    this.data = getProperty(headers, (this.type || "").toLowerCase(), []);
    this.headers = Object.keys(this.data);
    this.includeGovDB = this.headers.includes("Government Database");
    this.kycVerificationService
      .getKycVerificationStatus(this.loanId, this.entityId, this.entityType)
      .subscribe(
        (response: any) => this.handleKycResults(response),
        (error) => {
          console.error(error);
          this.snackbar.open("Error while KYC Verification", "", {
            duration: 4000,
          });
        }
      );
  }

  handleKycResults(response: any = {}) {
    const allResults: Array<any> = getProperty(response, "results", []);
    const currentResult = allResults.find(
      (result) => getProperty(result, "documentIdNo", "") === this.idNo
    );
    const results = getProperty(currentResult, "result", {});
    this.results = Object.keys(results).map((key) => {
      return {
        type: key,
        ...results[key],
      };
    });

    this.results = this.results.map((result) => ({
      ...result,
      class: this.getStatusClass(result["status"]),
    }));
    this.updateResultsByCriteria("Name");
    this.updateResultsByCriteria("DOB");
    this.enableVerifyBtn = Object.values(results).every(
      (item) =>
        (this.includeGovDB && item["governmentDatabase"] === null) ||
        item["kycDetails"] === null
    );
    const matchValue = this.getMatchValue();

    this.matchValueData.next(matchValue ? "Not Match" : "Match");
    this.matchValueClass.next(matchValue ? "failed" : "accept");
    this.results.sort((a, b) => {
      return (
        this.resultsOrder.indexOf(a.type) - this.resultsOrder.indexOf(b.type)
      );
    });
    this.resultsToUpdate = this.transformData(this.results || []);
  }

  updateResultsByCriteria(resultType: string = "") {
    if ("pan".includes(this.type.toLowerCase())) {
      let nameIndex = this.results.findIndex(
        (result) => result?.type === resultType
      );
      set(this.results, `[${nameIndex}].governmentDatabase`, "Not Available");
      let matchStatusText =
        this.results[nameIndex].class === "failed" ? "does not" : "";
      set(
        this.results,
        `[${nameIndex}].helperText`,
        `${resultType} ${matchStatusText} match with govt records`
      );
    }
  }

  getMatchValue() {
    return this.results.some((result) =>
      (getProperty(result, "status") || "").includes("Not Match")
    );
  }
  updateDetailsDisplay() {
    this.showDetail = !this.showDetail;
  }
  getStatusClass(matchValue: string = "") {
    if (!matchValue) {
      return "";
    }
    return matchValue.includes("Not Match") ? "failed" : "accept";
  }
  verifyKycManually() {
    const requestPayload = {
      loanApplicationId: this.loanId,
      documentType: this.type,
      documentIdNo: this.idNo ?? "",
      entityId: this.entityId,
      customerType: this.entityType,
      purpose: this.purpose,
    };
    this.kycVerificationService.verifyKyc(requestPayload).subscribe(
      (response) => {
        this.handleKycResults(response);
        if (!this.idNo) {
          window.location.reload();
        }
      },
      (error) => {
        console.error(error);
        this.snackbar.open("Error while KYC Verification", "", {
          duration: 4000,
        });
      }
    );
  }
  transformData(data: any[]): any[] {
    return data.reduce((acc, item) => {
      if (this.allowedTypes.includes(item.type)) {
        const updatedValue = item?.governmentDatabase
          ? item.governmentDatabase
          : item.kycDetails;
        if (updatedValue !== null && item?.status === 'Not Match') {
          acc.push({
            label: item.type,
            existingValue: item.applicant,
            updatedValue,
            class: item.class,
          });
        }
      }
      return acc;
    }, []);
  }
  openUpdateDialog() {
    const updateConfirmation = this.dialog.open(
      KycResultsUpdatePopupComponent,
      {
        maxWidth: "50vw",
        data: {
          results: this.resultsToUpdate,
          entityId: this.entityId,
          customerType: this.entityType,
          documentType: this.type,
          documentIdNo: this.idNo,
          purpose: this.purpose,
        },
      }
    );
    updateConfirmation.afterClosed().subscribe((response: any = null) => {
      console.log(response, "closed");
    });
  }
}
