import { Component, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { get } from "lodash";
import { JhiAlertService } from "ng-jhipster";
import { SubscriptionReviewSupportService } from "../../../services/customer-group/subscription-review/subscription-review-support.service";
import { SubscriptionReviewService } from "../../../services/customer-group/subscription-review/subscription-review.service";
import { FileService } from "../../../services/files/file.service";
import { RejectReasonPipe } from "../../../shared/pipes/ig-custom-refcode.pipe";
import { DocumentTypes } from "../../constant";
import { KcreditLoanService } from "../../kcredit-loan.service";
import { KcreditLoanDetailsModel } from "../../kcredit-loanDetails.model";
import { ImageEditorPopupService } from "../image-editor/image-editor-popup.service";
import { ImageEditorComponent } from "../image-editor/image-editor.component";

@Component({
  selector: "jhi-loan-application",
  templateUrl: "./loan-application.component.html",
  styleUrls: ["../../kcredit-loan.css"],
})
export class LoanApplicationComponent {
  @Input() loanDetails: KcreditLoanDetailsModel;
  @Input() disableEdit: boolean;
  updatedDocument: any = [];
  public service: KcreditLoanService;
  public documents: any[] = [];
  readonly DocumentTypes = DocumentTypes;
  modalRef: NgbModalRef;
  fileDTOs: any[];
  disableAccept: boolean = false;
  docReviewStatus: any;

  constructor(
    public domSanitizer: DomSanitizer,
    private kcreditLoanService: KcreditLoanService,
    private rejectReasonPipe: RejectReasonPipe,
    private subscriptionReviewSupportService: SubscriptionReviewSupportService,
    private fileService: FileService,
    private imageEditorPopupService: ImageEditorPopupService,
    private customerReviewService: SubscriptionReviewService,
    private alertService: JhiAlertService
  ) {}

  ngOnInit() {
    this.getDocumentsByType();
  }

  getDocumentsByType() {
    if (this.loanDetails.loanApplicationDocumentDTOList) {
      this.documents = [];
      this.loanDetails.loanApplicationDocumentDTOList.forEach((x, i) => {
        x["disableAccept"] = false;
        if (x.documentType == DocumentTypes.application) {
          if (x.reviewStatus === "REJECT") {
            x.rejectStatus = true;
            x["disableAccept"] = true;
          } else if (x.reviewStatus === "ACCEPT") {
            x["disableAccept"] = true;
          }
          this.documents.push(x);
        }
      });
    }
  }

  editImage(entry, feature) {
    this.modalRef = this.imageEditorPopupService.open(
      <Component>ImageEditorComponent,
      entry.image,
      feature
    );
    this.modalRef.result.then((value) => {
      this.updatedDocument[entry.documentType] = value;
      entry.image = value;
    });
  }
  updateDocument(loanAppDoc) {
    loanAppDoc.disableAccept = true;
    const files = [];
    const updatedDocTypes = Object.keys(this.updatedDocument);

    // this.documents.forEach((loanAppDoc) => {
    if (
      loanAppDoc.rejectStatus &&
      (loanAppDoc.reviewRemarks === null ||
        loanAppDoc.reviewRemarks === undefined)
    ) {
      setTimeout(() => {
        this.alertService.error("Please provide the Reject Reason");
      }, 100);
      return;
    }

    if (loanAppDoc.rejectStatus)
      loanAppDoc.reviewStatus =
        loanAppDoc.rejectStatus === true ? "REJECT" : "ACCEPT";

    let imageBlob;

    if (updatedDocTypes.indexOf(loanAppDoc.documentType) > -1) {
      imageBlob = this.subscriptionReviewSupportService.dataURItoBlob(
        this.updatedDocument[loanAppDoc.documentType]
      );
    }
    if (imageBlob) {
      const imageFile = new File(
        [imageBlob],
        loanAppDoc.documentType + ".jpg",
        { type: "image/jpeg" }
      );
      files.push(imageFile);
    }
    // });
    if (files.length > 0) {
      this.fileService
        .uploadKaleidofinImage(
          files,
          "S3",
          this.loanDetails.customerDTO.partnerId
        )
        .subscribe((res) => {
          this.fileDTOs = res;
          if (this.fileDTOs !== undefined) {
            const updatedDocTypes = Object.keys(this.updatedDocument);
            let currentFile = 0;
            if (updatedDocTypes.indexOf(loanAppDoc.documentType) > -1) {
              loanAppDoc.documentFileId = this.fileDTOs[currentFile].id;
            }
            this.flushDocument(loanAppDoc);
          }
        });
    } else {
      this.flushDocument(loanAppDoc);
    }
  }

  flushDocument(loanAppDoc) {
    this.customerReviewService
      .saveLoanDocuments(this.loanDetails.loanApplicationDTO.id, [loanAppDoc])
      .subscribe(() => {
        this.getDocumentsByType();
      });
  }
  openPdf(base64Image) {
    this.imageEditorPopupService.openPdf(base64Image);
  }
  displayRejectReasonError(document) {
    let reviewRemarks = get(document, "reviewRemarks", null);
    if (typeof reviewRemarks === "string")
      return get(document, "reviewRemarks.length", 0) < 1;
    return reviewRemarks == null;
  }
  verifyDocType(
    document: any = {},
    docType: string[] = ["pdf", "jpg", "jpeg", "png", "jpg", "svg"]
  ) {
    let documentName: string = get(document, "documentName", "") || "";
    if (documentName) {
      return docType.includes(
        documentName.substring(documentName.lastIndexOf(".") + 1)
      );
    }
    return false;
  }
  disableBasedOnStatus(document) {
    return (
      ["REJECT", "ACCEPT"].indexOf(get(document, "reviewStatus", "")) !== -1
    );
  }
}
