import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { cloneDeep, get } from "lodash";
import {
  DocReviewType,
  ReviewType,
} from "../../../models/customer-group/review-enum.model";
import { ReferenceCode } from "../../../models/reference-code/reference-code.model";
import { SubscriptionReviewSupportService } from "../../../services/customer-group/subscription-review/subscription-review-support.service";
import { SubscriptionReviewService } from "../../../services/customer-group/subscription-review/subscription-review.service";
import { CustomerService } from "../../../services/customer/customer.service";
import { FileService } from "../../../services/files/file.service";
import { RejectReasonPipe } from "../../../shared/pipes/ig-custom-refcode.pipe";
import { Errors, ObligatorTypes } from "../../constant";
import { KcreditLoanDetailsModel } from "../../kcredit-loanDetails.model";
import { ImageEditorPopupService } from "../image-editor/image-editor-popup.service";
import { ImageEditorComponent } from "../image-editor/image-editor.component";

@Component({
  selector: "jhi-co-applicants",
  templateUrl: "./co-applicants.component.html",
  styleUrls: ["../../kcredit-loan.css"],
})
export class CoApplicantsComponent {
  @Input() loanDetails: KcreditLoanDetailsModel;
  @Input() disableEdit: boolean;
  @Input() coApplicants: any[];
  @Input() loanId: any;
  @Input() partnerId: any;
  @Input() riskCategoryEnabled: boolean = false;
  @Output() reloadAfterSave = new EventEmitter<any>();

  readonly ObligatorTypes = ObligatorTypes;
  readonly errorConstants = Errors;

  public errorMatcher = null;
  public coApplicantDocs = [];
  public coApplicantDocsByPurpose = {};
  public modalRef: NgbModalRef;
  public docReviewStatus: any;
  public updatedDocument: any = {};
  public fileDTOs: any[];
  public disableAcceptKyc: boolean = false;
  public kycRejectReasons: ReferenceCode[] = [];
  initialCoApplicants: any[];
  editCoApplicantDetails: boolean = false;
  poiPanelOpenState: boolean = false;
  poaPanelOpenState: boolean = false;
  maxDate: Date;
  minDate: Date;
  panelOpenState: boolean = true;
  
  constructor(
    public domSanitizer: DomSanitizer,
    private subscriptionReviewService: SubscriptionReviewService,
    private imageEditorPopupService: ImageEditorPopupService,
    private subscriptionReviewSupportService: SubscriptionReviewSupportService,
    private fileService: FileService,
    private customerService: CustomerService,
    private datePipe: DatePipe,
    public rejectReasonPipe: RejectReasonPipe
  ) {}

  ngOnInit() {
    this.maxDate = new Date();
    this.minDate = new Date(1930, 1, 1);
    this.coApplicants.forEach((coApplicant) =>
      this.getCoApplicantKycDocs(coApplicant)
    );
    this.initialCoApplicants = cloneDeep(this.coApplicants);
  }

  loadReferenceCodes() {
    this.customerService
      .getCompleteReferanceCodes(["RejectReason"])
      .subscribe((res) => {
        const rejectReasons = res.RejectReason;
        for (let elm of rejectReasons) {
          const value = elm;
          if (value.field1 === "KYC") {
            this.kycRejectReasons.push(value);
          }
        }
      });
  }
  fetchDocReviewStatus() {
    this.subscriptionReviewService
      .getDocReviewStatus(
        this.loanDetails.customerDTO.id,
        DocReviewType.CO_APPLICANT_KYC,
        "IMAGE",
        null,
        null
      )
      .subscribe(
        (res) => {
          this.disableAcceptKyc =
            ["REJECT", "ACCEPT"].indexOf(get(res, "reviewStatus", "")) !== -1;
          this.docReviewStatus = res;
        },
        (err) => console.error(err)
      );
  }

  private fetchCoApplicantKycDocs(coApplicantKycList: any = []) {
    return coApplicantKycList.map((coApplicantData) => {
      let coApplicantKycData = {
        ...coApplicantData,
        rejectStatus: false,
        techIssueStatus: false,
      };

      return coApplicantKycData;
    });
  }

  public fetchBase64(coApplicantObj: any) {
    if (coApplicantObj !== null) return coApplicantObj["image"];
    return "--";
  }
  public fetchDocumentType(coApplicantObj: any) {
    return get(coApplicantObj, "documentType", "---");
  }

  editImage(docData, feature) {
    this.modalRef = this.imageEditorPopupService.open(
      <Component>ImageEditorComponent,
      docData.image,
      feature
    );
    this.modalRef.result.then((value) => {
      this.updatedDocument[docData.documentType] = value;
      docData.image = value;
    });
  }

  getField3(coApplicant) {
    if (coApplicant) {
      return `${coApplicant.purpose}_${
        (get(coApplicant, "documentType", "") || "").includes("front")
          ? "FRONT"
          : "BACK"
      }`;
    }
    return "";
  }
  changeRejectReason(coApplicant, field2, field4) {
    let field3 = this.getField3(coApplicant);
    if (field4 === "TECH_ISSUE") {
      coApplicant.techReviewOptionList = [];
      coApplicant.techReasonOptionsModel = [];
      coApplicant.techReviewOptionList = this.rejectReasonPipe.transform(
        this.kycRejectReasons,
        "KYC",
        field2,
        field3,
        field4
      );
    } else {
      coApplicant.reviewOptionList = [];
      coApplicant.reasonOptionsModel = [];
      coApplicant.reviewOptionList = this.rejectReasonPipe.transform(
        this.kycRejectReasons,
        "KYC",
        field2,
        field3,
        null
      );
    }
  }

  updateDocumentPurpose(doc, request: any = {}) {
    this.updateDocStatus(
      doc,
      "rejectStatus",
      "reviewStatus",
      "reviewRemarks",
      get(doc, "reviewRemarks")
    );
    if (doc.documentType.includes("front")) {
      request[`${doc.purpose}_front`] = doc;
    } else {
      request[`${doc.purpose}_back`] = doc;
    }
  }
  updateDocStatus(
    doc: any = {},
    conditionKey: string = "",
    statusKey: string = "",
    reviewReasonKey: string = "",
    reviewReasonValue: string = ""
  ) {
    if (get(doc, conditionKey, false)) {
      doc[statusKey] = ReviewType.REJECT;
      doc[reviewReasonKey] = reviewReasonValue;
    }
  }

  createRequestPayload(arrayList: any[] = [], request: any = {}) {
    arrayList.forEach((d) => {
      delete d.image;
      this.updateDocumentPurpose(request, d);
    });
  }

  saveEditedDocs() {
    const updatedDocTypes = Object.keys(this.updatedDocument);
    let imageBlob = undefined;
    const files = [];
    updatedDocTypes.forEach((docType) => {
      imageBlob = this.subscriptionReviewSupportService.dataURItoBlob(
        this.updatedDocument[docType]
      );
      if (imageBlob) {
        const imageFile = new File([imageBlob], docType + ".jpg", {
          type: "image/jpeg",
        });
        files.push(imageFile);
      }
    });

    let partnerId = get(this.loanDetails, "partnerDto.id", null);
    if (files.length > 0 && partnerId) {
      this.fileService
        .uploadKaleidofinImage(files, "S3", partnerId)
        .subscribe((res) => {
          this.fileDTOs = res;
          if (this.fileDTOs !== undefined) {
            const updatedDocTypes = Object.keys(this.updatedDocument);
            let currentFile = 0;
            this.updatedDocument.forEach((loanAppDoc) => {
              if (updatedDocTypes.indexOf(loanAppDoc.documentType) > -1) {
                loanAppDoc.documentFileId = this.fileDTOs[currentFile].id;
                currentFile = currentFile + 1;
              }
            });
          }
        });
    }
  }

  handleAndDisplayError(object = null, key = "") {
    let errorList = get(object, key, null);
    if (typeof errorList === "string")
      return get(object, `${key}.length`, 0) < 1;
    return errorList == null;
  }

  isRejected(coApplicant) {
    if (!coApplicant) return false;
    return (
      ["Rejected", "REJECT"].includes(
        get(coApplicant, "verificationStatus", "")
      ) ||
      (get(coApplicant, "verificationStatus", "") === "Pending" &&
        this.disableAcceptKyc)
    );
  }

  displayRejectReason(coApplicant) {
    let id = coApplicant?.message;
    let reason = this.kycRejectReasons.find((reason) => reason.code === id);
    return reason?.name || coApplicant?.message;
  }

  updateDate(coApplicant, dateObj = {}, key = "") {
    if (dateObj["value"]) {
      const date: Date = new Date(dateObj["value"]);
      const dateString = this.datePipe.transform(date, "yyyy-MM-dd");
      coApplicant[key] = dateString;
    }
  }

  getCoApplicantKycDocs(coApplicant: any = {}) {
    let docs = this.loanDetails.coapplicantKycDetailsList.filter(
      (document: any) => document?.entityId === coApplicant?.id
    );
    coApplicant["kycDocuments"] = docs;
  }
}
