<mat-form-field appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <input 
  matInput 
  [(ngModel)]="dataObj.value" 
  [disabled]="!editable || disabled" 
  [maxlength]="inputLength" 
  [required]="isRequired" 
  />
</mat-form-field>
