<div>
  <div class="sub-heading-container">
    <div class="section-title">ki score</div>
    <div class="ki-score-button-container">
      <button
        mat-raised-button
        type="button"
        class="btn-primary"
        [disabled]="disableKiScoreRetryEligibility"
        (click)="retryKiScore()"
      >
        <span>Retry ki score</span>
      </button>
      <button
        mat-raised-button
        type="button"
        class="btn-primary"
        [disabled]="hideKiScoreReport"
        (click)="openKiScoreReport()"
      >
        <span>View Report</span>
      </button>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-2">
      <label class="form-control-label" htmlFor="label-1">Actual Value </label>
    </div>
    <div class="col-md-2">
      <label class="form-control-label" htmlFor="label-2">Cut off Value </label>
    </div>
    <div class="col-md-2">
      <label class="form-control-label" htmlFor="label-3">Result </label>
    </div>
    <div class="col-md-6" *ngIf="kiScoreReport?.kiScoreDecision === 'ERROR'">
      <label class="form-control-label" htmlFor="label-4">Error Message</label>
    </div>
  </div>

  <div class="row">
    <div class="col-md-2">
      <jhi-null-replace
        [value]="kiScoreReport?.kiScoreValue"
      ></jhi-null-replace>
    </div>
    <div class="col-md-2">
      <jhi-null-replace
        [value]="kiScoreReport?.kiScoreCutOffValue"
      ></jhi-null-replace>
    </div>
    <div class="col-md-2" *ngIf="kiScoreReport?.kiScoreDecision !== null">
      <jhi-null-replace
        [ngClass]="VIEW_MAPPER[kiScoreReport?.kiScoreDecision]"
        [value]="VIEW_MAPPER[kiScoreReport?.kiScoreDecision]"
      ></jhi-null-replace>
    </div>
    <div class="col-md-5" *ngIf="kiScoreReport?.kiScoreDecision === 'ERROR'">
      <jhi-null-replace
        [value]="kiScoreReport?.errorMessage"
      ></jhi-null-replace>
    </div>
  </div>

</div>
